<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-6 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0 row">
          <div
            class="h-75px col-12 my-0 py-0 col-sm-6"
            v-if="serverData.organizations"
          >
            <v-select
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              required
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px col-12 my-0 py-0 col-sm-6">
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="customersList"
              clearable
              outlined
              dense
              required
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.host"
              label="Host"
              clearable
              outlined
              dense
              :error-messages="hostErrors"
              @input="$v.formData.host.$touch()"
              @blur="$v.formData.host.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.port"
              label="Port"
              clearable
              outlined
              dense
              type="number"
              :min="0"
              :error-messages="portErrors"
              @input="$v.formData.port.$touch()"
              @blur="$v.formData.port.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.username"
              label="Username"
              clearable
              outlined
              dense
              :error-messages="usernameErrors"
              @input="$v.formData.username.$touch()"
              @blur="$v.formData.username.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.password"
              label="Password"
              clearable
              outlined
              dense
              :error-messages="passwordErrors"
              @input="$v.formData.password.$touch()"
              @blur="$v.formData.password.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.encryption"
              label="Encryption"
              clearable
              outlined
              dense
              :error-messages="encryptionErrors"
              @input="$v.formData.encryption.$touch()"
              @blur="$v.formData.encryption.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.from_address"
              label="From address"
              clearable
              outlined
              dense
              :error-messages="from_addressErrors"
              @input="$v.formData.from_address.$touch()"
              @blur="$v.formData.from_address.$touch()"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.from_name"
              label="From name"
              clearable
              outlined
              dense
              :error-messages="from_nameErrors"
              @input="$v.formData.from_name.$touch()"
              @blur="$v.formData.from_name.$touch()"
            />
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetForm"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        customer_id: { required },
        organization_id: { required },
        host: { required },
        port: { required },
        username: { required },
        password: { required },
        encryption: { required },
        from_address: { required },
        from_name: { required },
        name: { required },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      organization_id: null,
      customer_id: null,
      host: null,
      port: null,
      username: null,
      password: null,
      encryption: null,
      from_address: null,
      from_name: null,
      name: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = {};
      let url = "create";
      if (this.actionType) {
        data = { id: this.$store.getters.getItemForAction.id };
        url = "edit";
      }
      ApiService.post("/settings/smtp_settings/" + url, data)
        .then((response) => {
          this.serverData = response.data;
          if (this.actionType) {
            this.formData = response.data.smtp_setting;
          }

          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    toggleModal() {
      if (this.dialog) {
        this.resetForm();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },

    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let actionUrl = "store";
        if (this.actionType) actionUrl = "update";
        ApiService.post(`/settings/smtp_settings/${actionUrl}`, {
          ...this.formData,
        })
          .then(() => {
            if (this.actionType) swalEdited();
            else swalAdded();
            this.refresher();
            this.toggleModal();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetForm() {
      this.$v.$reset();
      this.formData = {
        organization_id: null,
        customer_id: null,
        host: null,
        port: null,
        username: null,
        password: null,
        encryption: null,
        from_address: null,
        from_name: null,
        name: null,
      };
    },
  },
  computed: {
    customersList() {
      if (this.serverData.organizations) {
        return this.serverData.customers.filter(
          (customer) =>
            customer.organization_id == this.formData.organization_id
        );
      } else {
        return this.serverData.customers;
      }
    },
    organization_idErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    hostErrors() {
      return this.handleFormValidation("host", this);
    },
    portErrors() {
      return this.handleFormValidation("port", this);
    },
    usernameErrors() {
      return this.handleFormValidation("username", this);
    },
    passwordErrors() {
      return this.handleFormValidation("password", this);
    },
    encryptionErrors() {
      return this.handleFormValidation("encryption", this);
    },
    from_addressErrors() {
      return this.handleFormValidation("from_address", this);
    },
    from_nameErrors() {
      return this.handleFormValidation("from_name", this);
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit_item");
      else return this.$t("add_new_item");
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    // customer_idErrors: function () {
    //   return this.handleFormValidation("customer_id", this);
    // },
  },
};
</script>
