<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white">{{ item.title }}</v-card-title>
    <v-card-text class="pt-2">
      <template v-if="item.data.length > 0">
        <div
          v-for="(element, index) in item.data"
          :key="index"
          class="d-flex justify-space-between align-center mb-3"
        >
          <span class="font-weight-bolder font-size-lg pr-3"
            >{{ element.title }}:
          </span>
          <div>
            <a
              target="_blank"
              class="btn btn-sm btn-light-info font-size-sm"
              :href="element.file"
              >Click to open file</a
            >
          </div>
        </div>
      </template>
      <span v-else class="font-weight-bolder"
        >There isn't any uploaded documents</span
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PopUpDocs",
  props: ["item"],
};
</script>

<style></style>
