<template>
  <div>
    <div class="" v-if="exampleFileData">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData[0].need_to_show_message"
      >
        <span v-html="exampleFileData[0].message"></span>
      </v-alert>
    </div>
    <div class="px-6">
      <div class="row">
        <div class="col-12 col-sm-6" v-if="importTypeData.data?.customers">
          <v-select
            clearable
            dense
            outlined
            :label="$t('customer')"
            v-model="formData.customer_id"
            :items="importTypeData.data.customers"
            item-text="text"
            item-value="index"
            class="px-2"
            :error-messages="customer_idErrors"
            @input="$v.formData.customer_id.$touch()"
            @blur="$v.formData.customer_id.$touch()"
          ></v-select>
        </div>
        <div class="col-12 col-sm-6">
          <v-text-field
            v-model="formData.client_reference"
            label="Client reference"
            outlined
            dense
            :error-messages="client_referenceErrors"
            @input="$v.formData.client_reference.$touch()"
            @blur="$v.formData.client_reference.$touch()"
          />
        </div>
        <div class="col-12">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            :label="$t('file_input')"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          ></v-file-input>
        </div>
        <div
          class="col-12 col-sm-4 col-md-3 text-center"
          v-if="exampleFileData"
        >
          <v-btn class="primary--text" outlined text :href="exampleFileData">
            {{ $t("download_template") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        {{ $t("clear") }}
      </button>
      <button
        type="submit"
        class="btn btn--export-filter px-5 py-3 ls1"
        @click="submitForm"
      >
        {{ $t("upload") }}
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "StandardOrderPackagingForm",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        customer_id: {
          required: requiredIf(function () {
            return this.importTypeData.data?.customers;
          }),
        },
        client_reference: { required },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
    formDataFromUrl: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    if (Object.entries(this.formDataFromUrl).length > 1) {
      this.formData.client_reference = this.formDataFromUrl.client_reference;
      this.formData.customer_id = this.importTypeData.data.customers.find(
        (customer) => customer.text == this.formDataFromUrl.customer
      )?.index;
    }
  },
  data: () => ({
    formData: {
      file: null,
      customer_id: null,
      client_reference: null,
    },
  }),
  computed: {
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    clients: function () {
      return this.importTypeData.data.customers;
    },
    exampleFileData: function () {
      if (this.importTypeData.data.files) {
        return this.importTypeData.data.files[0].url;
      } else {
        return null;
      }
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    client_referenceErrors() {
      return this.handleFormValidation("client_reference", this);
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.importTypeData.type);
      data.append("customer_id", this.formData.customer_id);
      data.append("client_reference", this.formData.client_reference);
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        file: null,
        customer_id: null,
        client_reference: null,
      };
      if (Object.entries(this.formDataFromUrl).length > 1) {
        this.formData.client_reference = this.formDataFromUrl.client_reference;
        this.formData.customer_id = this.importTypeData.data.customers.find(
          (customer) => customer.text == this.formDataFromUrl.customer
        )?.index;
      }
    },
  },
};
</script>

<style></style>
