<template>
  <div>
    <div class="">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData[0].need_to_show_message"
      >
        <span v-html="exampleFileData[0].message"></span>
      </v-alert>
    </div>
    <div class="px-6">
      <div v-if="!isClient">
        <v-autocomplete
          clearable
          dense
          outlined
          v-model="formData.customer"
          :items="customers"
          item-text="text"
          item-value="index"
          :placeholder="$t('choose_customer')"
          :error-messages="customerErrors"
          @input="$v.formData.customer.$touch()"
          @blur="$v.formData.customer.$touch()"
        ></v-autocomplete>
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-md-9">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            :label="$t('file_input')"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          ></v-file-input>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-center">
          <v-btn class="primary--text" outlined text :href="exampleFileData">
            {{ $t("download_template") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        {{ $t("clear") }}
      </button>
      <button
        type="submit"
        class="btn btn--export-filter px-5 py-3 ls1"
        @click="submitForm"
      >
        {{ $t("upload") }}
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ReturnForm",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        customer: {
          required: requiredIf(() => {
            return !this.isClient;
          }),
        },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    formData: {
      customer: null,
      file: null,
    },
  }),
  computed: {
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    customers: function () {
      return this.importTypeData.data.customers;
    },
    exampleFileData: function () {
      return this.importTypeData.data.files[0].url;
    },
    customerErrors() {
      return this.handleFormValidation("customer", this);
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.importTypeData.type);
      if (!this.isClient) data.append("customer_id", this.formData.customer);
      else data.append("customer_id", this.$store.getters.currentUser.data.id);
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        customer: null,
        file: null,
      };
    },
  },
};
</script>

<style></style>
