<template>
  <div>
    <div class="">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData[0].need_to_show_message"
      >
        <span v-html="exampleFileData[0].message"></span>
      </v-alert>
    </div>
    <div class="px-6 row">
      <div :class="!isClient ? 'col-12 col-sm-6' : 'col-12 mx-0 px-0'">
        <v-autocomplete
          clearable
          dense
          outlined
          v-model="formData.warehouse_id"
          :items="warehouses"
          item-text="text"
          item-value="index"
          :label="$t('warehouse')"
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-autocomplete>
      </div>
      <div class="col-12 col-sm-6 mx-0 px-0" v-if="!isClient">
        <v-autocomplete
          clearable
          dense
          outlined
          :label="$t('customer')"
          v-model="formData.customer_id"
          :items="clients"
          item-text="text"
          item-value="index"
          :placeholder="$t('choose_customer')"
          :error-messages="customer_idErrors"
          @input="$v.formData.customer_id.$touch()"
          @blur="$v.formData.customer_id.$touch()"
        ></v-autocomplete>
      </div>
      <div class="row ma-0 pa-0">
        <div class="col-12 col-sm-8 col-md-9 mx-0 px-0 ps-1">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            :label="$t('file_input')"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          ></v-file-input>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-end px-0 ps-2 mx-0">
          <v-btn
            class="primary--text w-100 py-5"
            outlined
            text
            :href="exampleFileData"
          >
            {{ $t("download_template") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-0 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        {{ $t("clear") }}
      </button>
      <button
        type="submit"
        class="btn btn--export-filter px-5 py-3 ls1"
        @click="submitForm"
      >
        {{ $t("upload") }}
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "BaqAsnForm",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        warehouse_id: { required },
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    formData: {
      customer_id: null,
      file: null,
      warehouse_id: null,
    },
  }),
  computed: {
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    clients: function () {
      return this.importTypeData.data.customers;
    },
    warehouses: function () {
      return this.importTypeData.data.warehouses;
    },
    exampleFileData: function () {
      const sample = this.importTypeData.data.files[0].url;
      return sample;
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.importTypeData.type);
      data.append("warehouse_id", this.formData.warehouse_id);
      if (!this.isClient) {
        data.append("customer_id", this.formData.customer_id);
      }
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        customer_id: null,
        file: null,
        warehouse_id: null,
      };
    },
  },
};
</script>

<style></style>
